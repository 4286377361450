<template>
  <v-container
    v-if="!is_loading_dn"
    fluid
    fill-height
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md4
      >
        <v-skeleton-loader
          class="mx-auto border"
          type="table-tbody"
        ></v-skeleton-loader>
      </v-flex>

      <v-flex
        md1
        d-flex
        align-center
        justify-center
        class="mb-8"
      >
        <v-divider vertical></v-divider>
      </v-flex>

      <v-flex
        xs12
        md7
      >
        <v-flex style="height: 70vh; overflow-y: scroll; overflow-x: hidden">
          <v-row dense>
            <v-col
              cols="12"
              sm="3"
              v-for="n in 9"
              :key="n"
            >
              <v-skeleton-loader
                class="mx-auto border"
                max-width="200"
                type="image, article"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>

  <v-container
    v-else
    fluid
    fill-height
  >
    <v-layout
      row
      wrap
    >
      <!-- first column -->
      <v-flex
        xs12
        md4
        class="align-content-space-between"
        style="display: flex; flex-direction: column"
      >
        <!-- sales details -->
        <div
          style="height: 50vh; min-height: 80px"
          class="scroll ml-8"
        >
          <h3 class="text-h4">{{ $t("pos_n.sale_details") }}</h3>
          <div
            @click="select(item)"
            v-for="item in listProductsSelected"
            :key="item.id"
          >
            <a></a>
            <div
              class="d-flex justify-space-between py-2"
              v-bind:class="{ 'bg-highlight': item.selected_cart }"
            >
              <div class="d-flex">
                <v-icon @click="remove(item)">mdi-close</v-icon>
              </div>
              <template>
                <v-img
                  v-if="item.image"
                  :src="item.image.url"
                  max-height="70"
                  max-width="70"
                />
                <v-img
                  v-else
                  src="@/assets/product.png"
                  max-height="35"
                  max-width="35"
                />
              </template>

              <div class="me-4">
                <a
                  class="ma-1 font-weight-regular"
                  v-if="item.name.length > 30"
                  style="color: black"
                  >{{ item.name.substring(0, 27) + ".." }}</a
                >

                <a
                  v-else
                  class="me-8"
                  style="color: black"
                  >{{ item.name }}</a
                >
                <br />
                <label class="text--disabled ma-1">
                  {{ itemLabel(item) }}
                </label>
                <br />
              </div>
              <p class="mt-2 font-weight-medium">
                {{ item.total_item.toFixed(2) }} DA
              </p>
              <div class="d-flex">
                <v-icon
                  class="mx-4"
                  v-if="item?.promotions?.length > 0"
                  @click="openPromotionModal(item)"
                >
                  $vuetify.icons.iconPromotion
                </v-icon>
                <div
                  v-else
                  style="width: 45px"
                ></div>
              </div>
            </div>

            <v-divider></v-divider>
          </div>
        </div>
        <!-- end sales details -->
        <v-divider class="ms-4"></v-divider>
        <!-- calculator -->
        <div
          style="height: 28vh; min-height: 200px"
          class="ml-8 mt-0"
        >
          <!-- total section -->
          <v-row class="mr-2">
            <v-spacer></v-spacer>
            <v-col md6>
              <div
                class="text-h5 font-weight-medium d-flex justify-space-between py-2"
                style="min-width: 200px"
              >
                <span>Total </span>
                <span>{{ total_ttc().toFixed(2) }}</span>
              </div>

              <div
                class="d-flex justify-space-between"
                style="min-width: 200px"
              >
                <p>Taxes</p>
                <p>{{ totalTva().toFixed(2) }}</p>
              </div>
              <v-divider class="ms-1"></v-divider>

              <div
                class="justify-space-between py-2"
                style="min-width: 200px"
              >
                <v-row justify="end">
                  <v-col cols="4">
                    <v-btn
                      text
                      icon
                      color="primary"
                      class="ma-0 pa-0"
                      @click="openRefundModal()"
                    >
                      <v-icon class="ms-6">mdi-reload</v-icon>
                      <span class="mx-2">{{ $t("pos_n.refund") }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      text
                      icon
                      class="ma-0 pa-0"
                      color="primary"
                      :disabled="!selected_cart_item"
                      @click="openmodal()"
                    >
                      <v-icon class="ms-6">mdi-information-outline</v-icon>
                      <span class="mx-2">infos</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <!-- end total section -->
          <v-layout
            row
            wrap
            class="justify-end"
          >
            <v-flex
              md3
              class="align-content-space-between mr-2"
              style="display: flex; flex-direction: column"
            >
              <v-text-field
                outlined
                hide-details
                v-model="current"
                dense
                :label="$t('quantity')"
                style="max-width: 120px"
                class="ms-n7"
                type="number"
                min="1"
                @keypress="isNumber($event)"
                :rules="[rules.quantity]"
              ></v-text-field>
              <v-btn
                v-bind:style="{
                  width: '120px',
                  height: '130px',
                  fontSize: '19px',
                }"
                class="ms-n7"
                color="primary"
                @click="openPayementModal()"
                >{{ $t("pos_n.payment") }}</v-btn
              >
            </v-flex>
            <v-flex md8>
              <div class="calculator">
                <div
                  v-for="n in 9"
                  :key="n"
                >
                  <div
                    @click="append(n)"
                    class="btn"
                    v-ripple
                    role="button"
                  >
                    {{ n }}
                  </div>
                </div>
                <div>
                  <div
                    @click="append('+/-')"
                    class="btn"
                    v-ripple
                    role="button"
                  >
                    +/-
                  </div>
                </div>
                <div>
                  <div
                    @click="append(0)"
                    class="btn"
                    v-ripple
                    role="button"
                  >
                    0
                  </div>
                </div>
                <div>
                  <div
                    @click="dot()"
                    class="btn"
                    v-ripple
                    role="button"
                  >
                    ,
                  </div>
                </div>
                <div>
                  <div
                    @click="clearAll()"
                    class="btn"
                    v-ripple
                    role="button"
                  >
                    <v-icon large> mdi-backspace-outline </v-icon>
                  </div>
                </div>
                <div
                  @click="addQuantity()"
                  class="quantity"
                  v-ripple
                  role="button"
                >
                  {{ $t("pos_n.qte") }}
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <!-- end calculator -->
      </v-flex>
      <!-- end first column -->
      <!-- divider -->
      <v-flex
        md1
        d-flex
        align-center
        justify-center
        class="mb-8"
      >
        <v-divider vertical></v-divider>
      </v-flex>

      <!-- end divider -->
      <!-- second column -->
      <v-flex
        xs12
        md7
      >
        <v-data-table
          :items="displayedItems"
          :loading="is_loading_product"
          :server-items-length="totalItems"
          :options.sync="options"
          :items-per-page="16"
          fill-height
          hide-default-footer
        >
          <template v-slot:top>
            <v-row justify="end">
              <v-btn
                color="primary"
                outlined
                @click="getAllProducts()"
                :loading="isLoadingProductsToLDB"
                :disabled="isLoadingProductsToLDB"
                class="mr-4"
              >
                {{ $t("fetch_new_products") }}
              </v-btn>
              <v-dialog
                v-model="isVisibleCheckoutDialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t("generate_pos_checkout") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">{{
                    $t("generate_pos_checkout_confirmation_message")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="closeForm"
                      >{{ $t("cancel") }}</v-btn
                    >
                    <v-btn
                      color="primary"
                      text
                      @click="printPOSCheckout()"
                      >{{ $t("yes") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            <div style="margin-top: -45px">
              <h3 class="text-h4">{{ $t("pos_n.select") }}</h3>
              <div class="my-4">
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      ref="searchField"
                      autofocus
                      outlined
                      hide-details=""
                      dense
                      :label="$t('search')"
                      v-model="options.search"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
          </template>
          <template v-slot:body="{ items }">
            <v-flex
              style="height: 70vh; overflow-y: scroll; overflow-x: hidden"
            >
              <v-row
                dense
                v-if="items.length > 0"
              >
                <v-col
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-card
                    width="230"
                    height="260"
                    @click="addToCart(item)"
                  >
                    <v-card-text>
                      <div class="icon-container">
                        <v-icon
                          color="primary"
                          v-if="isProductSelected(item)"
                          >mdi-check</v-icon
                        >
                      </div>
                      <template>
                        <div
                          class="image-container"
                          v-if="item.image"
                        >
                          <v-img
                            :src="item.image.url"
                            height="130"
                          />
                        </div>
                        <v-img
                          class="ms-9"
                          v-else
                          v-bind:style="{
                            'min-height': '130px',
                            'min-width': '130px',
                            'object-fit': 'cover',
                            'background-image': 'url(\'@/assets/product.png\')',
                            'background-size': 'cover',
                          }"
                        />
                      </template>

                      <p class="mt-5 mb-3 text-h4 text--primary">
                        {{ item.name.substring(0, 18) + ".." }}
                      </p>
                      <p class="text--disabled mt-n3 mb-0 body-1">
                        {{ item.barcode }}
                      </p>
                      <p class="font-weight-medium">{{ item.price_ht }} DA</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-alert
                v-else-if="!is_loading_product"
                :value="true"
                icon="mdi-alert-circle-outline"
                >{{ $t("no_data_available") }}</v-alert
              >
            </v-flex>
          </template>
          <template v-slot:foot="{ pagination, options, updateOptions }">
            <div class="footer-container">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                :items-per-page-options="[8, 16, 24]"
                :items-per-page-text="$t('pos_n.per_page')"
              >
                <template
                  v-slot:page-text="{ pageStart, pageStop, itemsLength }"
                >
                  {{
                    $t("pos_n.displaying", {
                      from: pageStart,
                      to: pageStop,
                      total: itemsLength,
                    })
                  }}
                </template>
              </v-data-footer>
            </div>
          </template>
        </v-data-table>
        <product-info-modal
          v-if="isProductInfoModalOpen"
          :isProductInfoModalOpen="isProductInfoModalOpen"
          :toggleProductInfoModal="() => toggleProductInfoModal()"
          :productItem="currentItem"
        ></product-info-modal>
        <payement-modal
          v-if="isPayementModalOpen"
          :isPayementModalOpen="isPayementModalOpen"
          :togglePayementModal="togglePayementModal"
          :total="total_ttc()"
          :order="listProductsSelected"
        >
        </payement-modal>
        <refund-modal
          v-if="isRefundModalOpen"
          :isRefundModalOpen="isRefundModalOpen"
          :toggleRefundModal="() => toggleRefundModal()"
        >
        </refund-modal>
        <promotion-modal
          v-if="isPromotionModalOpen"
          :isPromotionModalOpen="isPromotionModalOpen"
          :product="currentItemPromotion"
          @reCalculate="total_ttc_item"
          :togglePromotionModal="() => togglePromotionModal()"
        ></promotion-modal>
      </v-flex>
      <!-- end second column -->
    </v-layout>
  </v-container>
</template>



<script>
import Localbase from "localbase";
import { validationRules } from "@/mixins/validationRules";
import { network } from "@/mixins/network";
import _ from "lodash";
import debounce from "lodash/debounce";
import { mapGetters } from "vuex";
import Vue from "vue";

let db = new Localbase("db");
db.config.debug = false;
import productService from "@/store/services/product-service";
export default {
  components: {
    ProductInfoModal: () => import("./modals/productInfoModal.vue"),
    PayementModal: () => import("./modals/payementModal.vue"),
    RefundModal: () => import("./modals/refundModal.vue"),
    PromotionModal: () => import("./modals/promotionModal.vue"),
  },
  mixins: [validationRules, network],
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  mounted() {
    db.collection("products")
      .get()
      .then(async (products) => {
        if (!_.isEmpty(products) && this.isSyncToday())
          this.listLocalProducts = products;
        else {
          this.getAllProducts();
        }
        this.is_loading_dn = true;
      });
    setInterval(() => {
      db.collection("products")
        .get()
        .then((doc) => {
          this.listLocalProducts = doc;
        });
    }, 100 * 1000);
    document.addEventListener("keypress", function (e) {
      if (e.code == "NumpadAdd" || e.code == "Equal") {
        document.getElementById("saveBtn").focus();
      }
      if (e.code == "NumpadSubtract") {
        document.getElementById("amount_received_input").focus();
      }
    });
  },
  computed: {
    ...mapGetters({
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
      lots: "lots/list",
      user: "getUser",
    }),
    totalItems() {
      const items = this.isOnline
        ? this.productsMeta.totalItems
        : this.displayedItems.length;
      return items;
    },
  },
  watch: {
    options: {
      handler: debounce(async function (params) {
        this.is_loading_product = true;
        if (this.isOnline) {
          params.hub_id = this.user?.hub_id;
          await this.$store.dispatch("products/list", params);
          this.displayedItems = this.listProducts;
          if (params.page > this.productsMeta.lastPage) {
            params.page = 1;
          }
        } else {
          this.displayedItems?.forEach((value) => {
            Vue.set(value, "selected", false);
          });
          this.displayedItems = this.listLocalProducts.filter((item) => {
            return (
              item.name
                .toLowerCase()
                .includes(this.options.search.toLowerCase()) ||
              item.barcode.includes(this.options.search)
            );
          });
        }
        this.is_loading_product = false;
      }, 1000),
      deep: true,
    },
  },
  data() {
    return {
      current: 1,
      paginatedItems: [],
      currentPage: 1,
      itemsPerPage: 16,
      page: 1,
      is_loading_product: false,
      isLoadingProductsToLDB: false,
      posOrder: {},
      listLocalProducts: [],
      networkProducts: [],
      displayedItems: [],
      isVisibleCheckoutDialog: false,
      is_loading_dn: false,
      is_saving: false,
      show_created_at_picker: null,
      search_product_input: "",
      is_valid: true,
      formErrors: [],
      options: { search: "" },
      orderProductToAdd: {
        qte_in_order: 1,
        discount: 0,
      },
      listProductsSelected: [],
      isProductInfoModalOpen: false,
      isPayementModalOpen: false,
      isRefundModalOpen: false,
      currentItemInCart: null,
      selected_cart_item: false,
      selectedItem: null,
      activatedProducts: [],
      isPromotionModalOpen: false,
    };
  },
  methods: {
    itemLabel(item) {
      const label =
        item.qte_in_order + " Unites a " + item.price_ht + " DA/Unites";
      if (item?.promotion) {
        return label + ` (${item.promotion.discount}%)`;
      }
      return label;
    },
    isProductSelected(item) {
      return this.activatedProducts.indexOf(item.id) !== -1;
    },
    cancelSearch() {
      this.options.search = "";
    },

    async addToCart(item) {
      // Check if item already exists in listProductsSelected

      const selectedItem = this.listProductsSelected.find(
        (selectedItem) => selectedItem.id === item.id
      );

      if (selectedItem) {
        // Item already exists, increment qte_in_order property
        selectedItem.qte_in_order++;
        // calculate total price ttc => ht*qte + (ht*qte)*tva/100
        selectedItem.total_item = this.total_ttc_item(selectedItem);
      } else {
        this.activatedProducts.push(item.id);
        // Item doesn't exist, add new item with qte of 1
        const newItem = {
          ...item,
          qte_in_order: 1,
          selected_cart: false,
          selected: true,
        };
        newItem.total_item = this.total_ttc_item(newItem);
        if (this.isOnline) {
          const hub_id = this.user?.hub_id;
          const promotions = await productService.getDiscounts(item.id, hub_id);
          if (promotions?.length > 0) {
            newItem.promotions = promotions;
          }
        }
        this.listProductsSelected.push(newItem);
      }
      this.$refs.searchField.focus();
    },
    remove(item) {
      const index = this.listProductsSelected.indexOf(item);
      if (index != -1) {
        if (item.selected_cart == true) this.selected_cart_item = false;
        this.listProductsSelected.splice(index, 1);
        item.selected = false;
        this.activatedProducts.splice(index, 1);
        if (this.currentItemInCart && item.id == this.currentItemInCart.id) {
          this.currentItemInCart = null;
        }
      }
    },
    clearAll() {
      this.current = 1;
    },
    append(number) {
      if (this.current == 0) this.current = `${number}`;
      else this.current = `${this.current}${number}`;
    },
    dot() {
      if (this.current.indexOf(".") == -1) this.append(".");
    },
    addQuantity() {
      if (this.currentItemInCart) {
        const selectedItem = this.listProductsSelected.find(
          (selectedItem) => selectedItem.id === this.currentItemInCart.id
        );
        if (selectedItem) {
          selectedItem.qte_in_order = this.current;
          selectedItem.total_item = this.total_ttc_item(selectedItem);
          this.current = 1;
        }
      }
      this.$refs.searchField.focus();
    },
    calulateDiscountAmount(item) {
      if (!item?.promotion) {
        return 0;
      }
      const itemHt = this.item_ht(item);
      const discountAmount =
        Math.round(itemHt * parseFloat(item?.promotion?.discount ?? 0)) / 100;
      const maxDiscountAmount = item?.promotion?.max_discount_amount ?? 0;

      return (item.reduction =
        maxDiscountAmount > 0
          ? Math.min(discountAmount, maxDiscountAmount)
          : discountAmount);
    },
    total_ttc_item(item) {
      const result =
        this.item_ht(item) +
        this.item_tva(item) -
        this.calulateDiscountAmount(item);
      return (item.total_item = Math.round(result * 100) / 100);
    },
    item_tva(item) {
      const tva = parseFloat(item.tva);
      return (item.total_tva =
        ((this.item_ht(item) - this.calulateDiscountAmount(item)) * tva) / 100);
    },
    item_ht(item) {
      return (item.ht =
        parseFloat(item.price_ht) * parseFloat(item?.qte_in_order ?? 1));
    },
    total_ttc() {
      var total = 0;
      this.listProductsSelected?.forEach((element) => {
        total += element.total_item;
      });
      return total;
    },
    totalTva() {
      var total = 0;
      this.listProductsSelected?.forEach((element) => {
        total += element.total_tva;
      });
      return total;
    },
    select(item) {
      this.currentItemInCart = item;
      if (this.selectedItem === item) {
        this.selectedItem = null;
        item.selected_cart = false;
        this.selected_cart_item = false;
      } else {
        if (this.selectedItem) {
          this.selectedItem.selected_cart = false;
          this.selected_cart_item = false;
        }
        item.selected_cart = true;
        this.selected_cart_item = true;
        this.selectedItem = item;
      }
    },
    async openmodal() {
      if (this.currentItemInCart) {
        await this.$store.dispatch("lots/list", {
          product_id: this.currentItemInCart.id,
        });
        this.isProductInfoModalOpen = true;
        this.currentItem = this.currentItemInCart;
      }
    },

    toggleProductInfoModal() {
      this.isProductInfoModalOpen = !this.isProductInfoModalOpen;
    },
    openPayementModal() {
      this.isPayementModalOpen = true;
    },
    openRefundModal() {
      this.isRefundModalOpen = true;
    },
    togglePayementModal(clearCart = false) {
      if (clearCart) {
        this.listProductsSelected = [];
        this.activatedProducts = [];
      }
      this.isPayementModalOpen = !this.isPayementModalOpen;
      this.$refs.searchField.focus();
    },
    toggleRefundModal() {
      this.isRefundModalOpen = !this.isRefundModalOpen;
    },
    togglePromotionModal() {
      this.isPromotionModalOpen = !this.togglePromotionModal;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        // charCode > 31 &&
        charCode < 48 ||
        charCode > 57
        // charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    openPromotionModal(item) {
      this.isPromotionModalOpen = true;
      this.currentItemPromotion = item;
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    isSyncToday() {
      const day = localStorage.getItem("day");
      const dateNow = new Date();
      if (dateNow.getDate() == day) {
        return true;
      }
      localStorage.setItem("day", dateNow.getDate());
      return false;
    },
    async getAllProducts() {
      this.isLoadingProductsToLDB = true;
      try {
        await db.collection("products").delete();
        const { products } = await this.$store.dispatch(
          "products/barodesQuery",
          {
            hub_id: this.user?.hub_id,
            all: "true",
          }
        );
        const mappedData = products.map((item) => ({
          id: item.id,
          barcode: item.barcode,
          type: item.type,
          name: item.name,
          price: item.price,
          price_ht: item.price_ht,
          purchase_price_ht: item.purchase_price_ht,
          tva: item.tva,
          selected: false,
          qte_in_hub: item.qte_in_hub,
          bundle_items: item.bundle_items,
          _key: item.barcode,
        }));
        await db.collection("products").set(mappedData, { keys: true });
        this.listLocalProducts = mappedData;
        this.isLoadingProductsToLDB = false;
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.statusText);
        this.isLoadingProductsToLDB = false;
      }
    },
    async printPOSCheckout() {
      try {
        this.is_loading_dn = false;
        await this.$store.dispatch("sync/syncOrders");
        const data = await this.$store.dispatch("orders/getPosCheckoutA7");
        const blob = new Blob([data], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);
        const iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        iframe.style.display = "none";
        iframe.id = "invoice_iframe";
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 100);
        };
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.statusText);
      } finally {
        this.is_loading_dn = true;
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisibleCheckoutDialog = false;
    },
  },
};
</script>
<style >
.calculator {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  text-align: center;
  font-size: 30px !important;
  width: 350px;
  margin-left: -30px;
}
.quantity {
  grid-column-start: 4;
  grid-row-start: 1;
  grid-row-end: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  border: 1px solid rgb(227, 224, 224);
  background-color: #5e2fbe !important;
}
.btn {
  border: 1px solid rgb(227, 224, 224);
}
.scroll {
  overflow-y: scroll;
}
.title-line-clamp {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.icon-container {
  width: 23px;
  height: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.footer-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.v-data-footer {
  font-size: 15px;
}

.bg-highlight {
  background-color: rgb(234, 229, 229);
}
</style>  